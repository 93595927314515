import { useSelector } from 'react-redux';
import { Global, ThemeProvider } from '@emotion/react';
import { Executions } from './components/executions/Executions';
import { Header } from './components/Header/Header';
import { getCurrentThemeSelector } from './store/selectors/app.selectors';
import './app.scss';
import { globalScrollStyles } from './components/customScroll/customScroll';

export const App = () => {
  const theme = useSelector(getCurrentThemeSelector);

  return (
    <>
      {!!theme && (
        <ThemeProvider theme={theme}>
          <Global styles={globalScrollStyles} />
          <Header />
          <Executions />
        </ThemeProvider>
      )}
    </>
  );
};
