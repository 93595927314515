import { of, catchError } from 'rxjs';
import { mergeMap, switchMap } from 'rxjs/operators';
import { AppEpic } from '../../../types';
import { fromFetch } from 'rxjs/fetch';
import { setAppJsonAction } from '../slices/appJson.slice';

export const requestAppJsonEpic: AppEpic = () =>
  of(null).pipe(
    mergeMap(() => {
      return fromFetch('/app.config.json').pipe(
          switchMap((response) => {
            if (response.ok) {
              return response.json();
            } else {
              return of({
                error: true,
                message: `Error ${response.status}`,
              });
            }
          }),
          catchError((err) => {
            console.error(err);
            return of({ error: true, message: err.message });
          }),
      )
    }),mergeMap((data) =>[
        setAppJsonAction(data)
      ]),
  );
