import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getActiveFormSelector,
  getFormsSelector,
  getSideSelector,
} from '../../store/selectors/app.selectors';
import { getExecutionsSelector } from '../../store/selectors/executions.selector';
import { Execution } from './Execution';
import { Totals } from './Totals';
import styled from '@emotion/styled';
import {
  borderRadius,
  padding,
  Flex1Vertical,
  marginBottom,
  getTheme,
  breakpoints,
  margin,
} from '@deltix/cc-ui-kit';
import { Diagnostic } from '../Diagnostic/Diagnostic';
import { getExecutionsAction } from '../../store/action/getExecutionsAction';

const Wrapper = styled(Flex1Vertical)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

const ExecutionWrapper = styled('div')(
  padding(1, 2),
  marginBottom(1),
  borderRadius(3),
  getTheme('orderList', 'container'),
  {
    [`@media (min-width: ${breakpoints[0]})`]: [
      {
        float: 'left',
        width: '260px',
      },
      margin(3),
    ],
  },
);

const ScrollbarWrapper = styled(Flex1Vertical)({
  overflowY: 'auto',
  [`@media (max-height: 250px)`]: [
    {
      overflowY: 'visible',
      flex: 'none',
      minHeight: 'auto',
    },
  ],
});

const DiagnosticWrapper = styled('div')({
  [`@media (min-width: ${breakpoints[0]})`]: [
    { columns: '2', columnGap: '100px' },
  ],
  [`@media (min-width: ${breakpoints[1]})`]: [{ columns: '3' }],
  [`@media (min-width: ${breakpoints[2]})`]: [{ columnGap: '200px' }],
});

export const Executions = () => {
  const dispatch = useDispatch();

  const forms = useSelector(getFormsSelector);
  const activeForm = useSelector(getActiveFormSelector);
  const side = useSelector(getSideSelector);
  const {
    executions,
    diagnostic,
    avgPrice,
    totalCommission,
    totalQty,
    timestamp,
    total,
    sorCommission,
    avgExchangePrice,
    quantity_presicion,
  } = useSelector(getExecutionsSelector);

  useEffect(() => {
    dispatch(getExecutionsAction());
  }, [forms, activeForm, side, dispatch]);

  return (
    <Wrapper>
      <div>
        {!!executions.length && (
          <Totals
            avgPrice={avgPrice}
            totalCommission={totalCommission}
            totalQty={totalQty}
            timestamp={timestamp}
            total={total}
            sorCommission={sorCommission}
            avgExchangePrice={avgExchangePrice}
            quantityPresicion={quantity_presicion}
          />
        )}
      </div>
      <ScrollbarWrapper>
        {!!executions.length &&
          executions.map((execution) => (
            <ExecutionWrapper key={execution.exchange}>
              <Execution
                execution={execution}
                quantityPresicion={quantity_presicion}
              />
            </ExecutionWrapper>
          ))}
      </ScrollbarWrapper>
      <DiagnosticWrapper>
        <Diagnostic diagnostic={diagnostic} />
      </DiagnosticWrapper>
    </Wrapper>
  );
};
