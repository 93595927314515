import ReactDOM from 'react-dom/client';
import { ApiProvider } from './app/api';
import store from './app/store/store';
import { Provider } from 'react-redux';
import { App } from './app/app';
import { library, IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faAngleDown,
  faInfoCircle,
  faSync,
} from '@fortawesome/free-solid-svg-icons';
import { EmotionCacheProvider } from '@deltix/cc-ui-kit';

library.add(
  faAngleDown as IconDefinition,
  faSync as IconDefinition,
  faInfoCircle as IconDefinition,
); // need change package version

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <EmotionCacheProvider>
    <Provider store={store}>
      <ApiProvider>
        <App />
      </ApiProvider>
    </Provider>
  </EmotionCacheProvider>,
);
