import { map, mergeMap, switchMap } from 'rxjs/operators';
import { AppEpic } from '../../../types';
import { getApi } from '../../api';
import { setFormAction, setSecurityNamesAction } from '../slices/app.slice';
import { EMPTY, merge, from } from 'rxjs';

const cache = new Map<string, string>();
let done = false;

export const formEpic: AppEpic = (action$, state$, dependencies) =>
  getApi().orderViewState$.pipe(
    switchMap((orders) => {
      const filteredOrders = orders.filter(
        (order) => !cache.has(order.security_id),
      );

      if (!filteredOrders.length) {
        return [setFormAction(orders)];
      }

      return merge(
        from(filteredOrders).pipe(
          mergeMap((order, index) => {
            return getApi()
              .getSecurity(order.security_id)
              .pipe(
                map((security) => {
                  if (security.name) {
                    cache.set(security.id, security.name);
                  }
                  if (filteredOrders.length - 1 === index) {
                    done = true;
                  }
                }),
              );
          }),
          switchMap(() => {
            if (done) {
              done = false;
              return [
                setFormAction(orders),
                setSecurityNamesAction(Object.fromEntries(cache.entries())),
              ];
            }
            return EMPTY;
          }),
        ),
      );
    }),
  );
