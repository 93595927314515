import { INewOrder } from '@deltix/cc-common';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { localStorageKey, SOROrderSide } from '../../../constants';
import { OrderSideType } from '../../../types';
import { fromLocalStorage } from '../../utils/localStorage';

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    side: fromLocalStorage(localStorageKey.SOR_SIDE) || SOROrderSide.BUY,
    form: [] as INewOrder[],
    securityNames: {} as Record<string, string>,
    activeForm: fromLocalStorage(localStorageKey.SOR_ACTIVE_FORM) || '',
    isVisibleDiagnostic:
      fromLocalStorage(localStorageKey.SOR_VISIBLE_DIAGNOSTIC) || false,
    theme: null,
    locale: null,
    settingTheme: null,
    isFullScreen: false,
  },
  reducers: {
    setSecurityNamesAction: (
      state,
      action: PayloadAction<Record<string, string>>,
    ) => {
      state.securityNames = action.payload;
    },
    setFormAction: (state, action: PayloadAction<INewOrder[]>) => {
      state.form = action.payload;
    },
    changeActiveFormAction: (state, action: PayloadAction<string>) => {
      state.activeForm = action.payload;
    },
    changeSideAction: (state, action: PayloadAction<OrderSideType>) => {
      state.side = action.payload;
    },
    changeVisibleDiagnosticAction: (state, action: PayloadAction<boolean>) => {
      state.isVisibleDiagnostic = action.payload;
    },
    setThemeAction: (state, action) => {
      state.theme = action.payload;
    },
    setLocaleAction: (state, action) => {
      state.locale = action.payload;
    },
    setSettingTheme: (state, action) => {
      state.settingTheme = action.payload;
    },
    setIsFullScreen: (state, action) => {
      state.isFullScreen = action.payload;
    },
  },
});

export const {
  setFormAction,
  changeActiveFormAction,
  changeSideAction,
  changeVisibleDiagnosticAction,
  setThemeAction,
  setLocaleAction,
  setSettingTheme,
  setIsFullScreen,
  setSecurityNamesAction,
} = appSlice.actions;

export default appSlice.reducer;
