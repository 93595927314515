import { createSelector } from 'reselect';
import { IReduxState } from '../../../types';

export const getFormsSelector = createSelector(
  (state: IReduxState) => state.app.form,
  (form) => form,
);

export const getSecurityNamesSelector = createSelector(
  (state: IReduxState) => state.app.securityNames,
  (securityNames) => securityNames,
);

export const getSideSelector = createSelector(
  (state: IReduxState) => state.app.side,
  (side) => side,
);

export const getActiveFormSelector = createSelector(
  getFormsSelector,
  (state: IReduxState) => state.app.activeForm,
  (forms, activeForm) => {
    const activeIndex = forms.findIndex(
      (security) => security.security_id === activeForm,
    );
    return activeIndex !== -1 ? activeIndex : 0;
  },
);

export const getVisibleDiagnosticSelector = createSelector(
  (state: IReduxState) => state.app.isVisibleDiagnostic,
  (isVisibleDiagnostic) => isVisibleDiagnostic,
);

export const getCurrentThemeSelector = createSelector(
  (state: IReduxState) => state.app.theme,
  (theme) => theme,
);

export const getLocaleSelector = createSelector(
  (state: IReduxState) => state.app.locale,
  (lang) => lang,
);

export const getCurrentFormSelector = createSelector(
  getFormsSelector,
  getActiveFormSelector,
  (forms, activeForm) => (!!forms.length ? forms[activeForm] : null),
);
