import { namespace } from '@deltix/logger';

const CACHE = new Map<string, boolean>();
const COMMA = ',';
const DOT = '.';

export const getNumberDecimalSeparator = (locale = 'en') => {
  if (CACHE.has(locale)) {
    return CACHE.get(locale) ? COMMA : DOT;
  }

  try {
    const intl = new Intl.NumberFormat(locale, {
      style: 'decimal',
    });
    const result = intl.format(1.1).includes(COMMA);

    CACHE.set(locale, result);

    return result ? COMMA : DOT;
  } catch (e: any) {
    namespace('app').warn('Failed to get locale info', e?.message);
  }

  CACHE.set(locale, false);

  return DOT;
};
