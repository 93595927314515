import styled from '@emotion/styled';
import { Flex, TextInput, borderRadius, getTheme } from '@deltix/cc-ui-kit';
import { BlueButtonStyles } from '../Buttons/Buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';

export const StyledInput = styled(TextInput)({
  cursor: 'pointer',
  padding: '1px 0px 1px 4px',
  width: '100%',
  textOverflow: 'ellipsis',
  border: 0,
});

const InputWrapper = styled(Flex)(
  getTheme(['inputSearch', 'fromTo']),
  borderRadius(2),
);

interface IDropdownInputProps {
  className?: string;
  value: string;
  onClick: () => void;
}

export const DropdownInput: FC<IDropdownInputProps> = ({
  className,
  value,
  onClick,
}) => {
  return (
    <InputWrapper className={className}>
      <StyledInput
        value={value}
        name={value}
        readOnly={true}
        onClick={onClick}
      />
      <BlueButtonStyles onClick={onClick}>
        <FontAwesomeIcon icon='angle-down' />
      </BlueButtonStyles>
    </InputWrapper>
  );
};
