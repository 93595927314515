import styled from '@emotion/styled';
import {
  borderBottomLeftRadius,
  borderBottomRightRadius,
  getTheme,
} from '@deltix/cc-ui-kit';

export const DropdownList = styled('div')(
  {
    position: 'absolute',
    width: '100%',
    top: '100%',
    zIndex: '100',
  },
  borderBottomLeftRadius(2),
  borderBottomRightRadius(2),
  getTheme(['dropdown', 'list']),
);
