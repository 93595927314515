import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAppJsonConfState } from '../../../types';

export const appJsonSlice = createSlice({
  name: 'appJson',
  initialState: {requestEndpoint: ''},
  reducers: {
    setAppJsonAction: (state, action: PayloadAction<IAppJsonConfState>) => {
      state.requestEndpoint = action.payload.requestEndpoint;
    },
  },
});

export const {
  setAppJsonAction
} = appJsonSlice.actions;

export default appJsonSlice.reducer;
