import Big from 'big.js';
import {
  breakpoints,
  marginBottom,
  marginTop,
  padding,
} from '@deltix/cc-ui-kit';
import { ZERO } from '@deltix/decimal-utils';
import styled from '@emotion/styled';
import { DEFAULT_DATETIME_FORMAT, ExecutionsLabel } from '../../../constants';
import { ExecutionsRow } from './ExecutionsRow';
import { HdDate } from '@deltix/hd-date';
import { convertExponentialToDecimal } from '../../utils/utils';

const ExecutionsRowWrapper = styled('div')(padding(1, 1), {
  [`@media (min-width: ${breakpoints[0]})`]: [
    { columns: '3' },
    marginTop(3),
    marginBottom(3),
  ],
  [`@media (min-width: ${breakpoints[1]})`]: [{ columnGap: '50px' }],
  [`@media (min-width: ${breakpoints[2]})`]: [{ columnGap: '110px' }],
  [`@media (min-width: ${breakpoints[3]})`]: [{ columnGap: '200px' }],
});

interface ITotalsProps {
  totalQty?: Big;
  totalCommission?: Big;
  avgPrice?: Big;
  timestamp: number;
  total?: Big;
  sorCommission?: Big;
  avgExchangePrice?: Big;
  quantityPresicion?: number;
}

export const Totals = (props: ITotalsProps) => {
  const {
    avgPrice,
    totalCommission,
    totalQty,
    timestamp,
    total,
    sorCommission,
    avgExchangePrice,
    quantityPresicion,
  } = props;

  return (
    <ExecutionsRowWrapper>
      {totalQty && (
        <ExecutionsRow
          textValue={ExecutionsLabel.TOTAL_QUANTITY}
          numberValue={convertExponentialToDecimal(totalQty)}
          presicion={quantityPresicion}
        />
      )}
      {avgExchangePrice && avgPrice && !avgExchangePrice.eq(avgPrice) && (
        <ExecutionsRow
          textValue={ExecutionsLabel.COVER_PRICE}
          numberValue={avgExchangePrice}
        />
      )}
      {totalCommission && (
        <ExecutionsRow
          textValue={ExecutionsLabel.TOTAL_COMMISSION}
          numberValue={totalCommission}
        />
      )}
      {sorCommission && !sorCommission.eq(ZERO) && (
        <ExecutionsRow
          textValue={ExecutionsLabel.SOR_COMMISSION}
          numberValue={sorCommission}
        />
      )}
      {avgPrice && (
        <ExecutionsRow
          textValue={ExecutionsLabel.AVG_PRICE}
          numberValue={avgPrice}
        />
      )}
      {total && (
        <ExecutionsRow textValue={ExecutionsLabel.TOTAL} numberValue={total} />
      )}
      <ExecutionsRow
        textValue={ExecutionsLabel.TIMESTAMP}
        numberValue={new HdDate(timestamp).toLocaleFormat(
          DEFAULT_DATETIME_FORMAT,
        )}
      />
    </ExecutionsRowWrapper>
  );
};
