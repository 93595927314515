export const toLocalStorage = (
  key: string,
  value: string | boolean | number,
) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const fromLocalStorage = (key: string) => {
  const value = localStorage.getItem(key);
  return value ? JSON.parse(value) : value;
};
