import { ETimeInForce } from '@deltix/cc-common';
import Big from 'big.js';
import { toDecimal } from '@deltix/decimal-utils';
import { SORTimeInForce } from '../../constants';

export const timeInForceConvert = (tif: ETimeInForce) => {
  switch (tif) {
    case ETimeInForce.day:
      return SORTimeInForce.DAY;
    case ETimeInForce.fok:
      return SORTimeInForce.FILL_OR_KILL;
    case ETimeInForce.gtc:
      return SORTimeInForce.GOOD_TILL_CANCEL;
    case ETimeInForce.gtd:
      return SORTimeInForce.GOOD_TILL_DATE;
    case ETimeInForce.ioc:
      return SORTimeInForce.IMMEDIATE_OR_CANCEL;
    default:
      return tif;
  }
};

const trimEnd = (str: string, char: string) => {
  let newLength = str.length;

  while (str[newLength - 1] && str[newLength - 1] === char) {
    newLength--;
  }

  return str.substr(0, newLength);
};

export const formatValue = (value: Big | number, precision = 6): string => {
  if (value instanceof Big) {
    let formattedValue = value.eq(0)
      ? '0'
      : trimEnd(value.toFixed(precision), '0');
    if (formattedValue[formattedValue.length - 1] === '.') {
      formattedValue = formattedValue.substr(0, formattedValue.length - 1);
    }

    return formattedValue;
  }
  if (typeof value === 'number') {
    return value === 0 ? '0' : value.toFixed(precision);
  }

  return value;
};

export const DEFAULT_DELIMITER = '\u00A0';

export const formatNumberByDelimiter = (
  num: string,
  decimalSeparator = '.',
  delimiter = DEFAULT_DELIMITER,
) => {
  let output = '';
  let hasDot = num.includes('.');
  const negative = +num.startsWith('-');

  for (let i = num.length - 1, p = -1; i >= negative; i--) {
    if (num[i] === '.') {
      output = decimalSeparator + output;
      hasDot = false;
      continue;
    }
    if (hasDot) {
      output = num[i] + output;
      continue;
    }

    if (p === -1) {
      p = 0;
    } else {
      p = (p + 1) % 3;
      if (p === 0) {
        output = delimiter + output;
      }
    }

    output = num[i] + output;
  }

  if (negative) {
    output = '-' + output;
  }

  return output;
};

export const convertExponentialToDecimal = (value: Big) =>
  toDecimal(value.toFixed());

export const prettyPrint = (message: string) => {
  if (!message) {
    return message;
  }

  return message
    .split(',')
    .map((text) => {
      return nameFromCamelCase(text);
    })
    .join('. ');
};

const nameFromCamelCase = (str: string) => {
  let name = str.replace(/([a-z](?=[A-Z]))/g, '$1 ');
  return name.charAt(0).toUpperCase() + name.slice(1);
};
