import { of } from 'rxjs';
import { combineEpics } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { AppEpic } from '../../../types';
import { formEpic } from './formEpic';
import { themeEpic } from './themeEpic';
import { requestExecutionsEpic } from './requestExecutionsEpic';
import { requestAppJsonEpic } from './requestAppJsonEpic';
import { localeEpic } from './localeEpic';

const epics = combineEpics(
  formEpic,
  themeEpic,
  requestExecutionsEpic,
  requestAppJsonEpic,
  localeEpic,
);

export const rootEpic: AppEpic = (action$, state$, dependencies) => {
  return of(null).pipe(switchMap(() => epics(action$, state$, dependencies)));
};
