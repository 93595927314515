import { getTheme } from '@deltix/cc-ui-kit';
import { css, Theme } from '@emotion/react';

// language=CSS
export const globalScrollStyles = (theme: Theme) => [
  css({
    '*': {
      scrollbarWidth: 'thin',
      // scrollBehavior: 'smooth',
      scrollbarColor: `${getTheme(['scrollBar', 'thumb', 'background'])({
        theme,
      })} ${getTheme(['scrollBar', 'scrollbar', 'background'])({
        theme,
      })}`,
      '::-webkit-scrollbar': {
        height: '7px',
        width: '7px',
      },
      '::-webkit-scrollbar-track': {
        borderRadius: '7px',
        ...getTheme(['scrollBar', 'scrollbar'])({
          theme,
        }),
      },
      '::-webkit-scrollbar-thumb': {
        borderRadius: '7px',
        opacity: 0.7,
        transition: 'opacity 0.2s linear, border 0.2s linear',
        ...getTheme(['scrollBar', 'thumb'])({ theme }),
        '&:hover': getTheme(['scrollBar', 'thumb', 'hover'])({ theme }),
      },
      '::-webkit-scrollbar-corner': {
        background: 'transparent',
      },
    },
  }),
];
