import { Flex1Horizontal } from '@deltix/cc-ui-kit';
import { useSelector } from 'react-redux';
import { getFormsSelector } from '../../store/selectors/app.selectors';
import styled from '@emotion/styled';
import { RefreshButton } from '../Buttons/Buttons';
import { DiagnosticToggle } from '../Buttons/DiagnosticToggle';
import { DropdownSecurities } from '../dropdownSecurities/DropdownSecurities';
import { DropdownSide } from '../DropdownSide/DropdownSide';
import { LIST_SIDE } from '../../../constants';
import { INewOrder } from '@deltix/cc-common';

const Flex1HorizontalStyled = styled(Flex1Horizontal)({
  display: 'flex',
  flex: 0,
  justifyContent: 'space-between',
  maxWidth: '250px',
});

export const Header = () => {
  const forms: INewOrder[] = useSelector(getFormsSelector);

  return (
    <Flex1HorizontalStyled>
      {!!forms.length && (
        <>
          <DropdownSecurities listItems={forms} />
          <DropdownSide listSide={LIST_SIDE} />
          <RefreshButton />
          <DiagnosticToggle />
        </>
      )}
    </Flex1HorizontalStyled>
  );
};
