import { configureStore } from '@reduxjs/toolkit';
import { createEpicMiddleware } from 'redux-observable';
import { rootEpic } from './epics/rootEpic';
import appSlice from './slices/app.slice';
import appJsonSlice from './slices/appJson.slice';
import executionsSlice from './slices/executions.slice';

const epicMiddleware = createEpicMiddleware();

export default configureStore({
  reducer: {
    app: appSlice,
    executions: executionsSlice,
    appJson: appJsonSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(epicMiddleware),
});

epicMiddleware.run(rootEpic as any);
