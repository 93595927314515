import styled from '@emotion/styled';
import Downshift from 'downshift';
import { DropdownList } from '../dropdown/DropdownList';
import { DropdownListItem } from '../dropdown/DropdownListItem';
import { useDispatch, useSelector } from 'react-redux';
import { getSideSelector } from '../../store/selectors/app.selectors';
import { changeSideAction } from '../../store/slices/app.slice';
import { OrderSideType } from '../../../types';
import { toLocalStorage } from '../../utils/localStorage';
import { localStorageKey } from '../../../constants';
import { DropdownInput } from '../Input/DropdownInput';

const SidesWrapper = styled('div')({
  display: 'flex',
  width: '70px',
  position: 'relative',
});

export const DropdownSide = ({ listSide }: { listSide: OrderSideType[] }) => {
  const dispatch = useDispatch();

  const selectedSide: OrderSideType = useSelector(getSideSelector);

  const changeSelectedItem = (index: number): void => {
    toLocalStorage(localStorageKey.SOR_SIDE, listSide[index]);
    dispatch(changeSideAction(listSide[index]));
  };

  return !!listSide.length ? (
    <Downshift>
      {({ isOpen, openMenu, closeMenu, getRootProps }) => {
        return (
          <SidesWrapper {...getRootProps()}>
            <DropdownInput
              value={selectedSide}
              onClick={isOpen ? closeMenu : openMenu}
            />
            {isOpen && (
              <DropdownList>
                {!!listSide.length &&
                  listSide.map((item, index: number) => (
                    <DropdownListItem
                      key={index}
                      active={item === selectedSide}
                      data-id={index}
                      onClick={() => {
                        changeSelectedItem(index);
                        (isOpen ? closeMenu() : openMenu()) as any;
                      }}
                    >
                      {item}
                    </DropdownListItem>
                  ))}
              </DropdownList>
            )}
          </SidesWrapper>
        );
      }}
    </Downshift>
  ) : null;
};
