import { switchMap, filter } from 'rxjs/operators';
import { AppEpic } from '../../../types';
import { getApi } from '../../api';
import { setThemeAction } from '../slices/app.slice';

export const themeEpic: AppEpic = (action$, state$, dependencies) =>
  getApi().userCustomSettings$.pipe(
    filter((settings) => settings.theme !== state$.value.app.settingTheme),
    switchMap(() => getApi().getCurrentTheme()),
    switchMap((theme) => [setThemeAction(theme)]),
  );
