import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BlueButton, Button } from '@deltix/cc-ui-kit';
import { useDispatch } from 'react-redux';
import { getExecutionsAction } from '../../store/action/getExecutionsAction';

export const BlueButtonStyles = styled(Button)({
  display: 'inline',
  opacity: 0.6,
  background: 'transparent',
  '&:hover': { opacity: 1 },
});

export const RefreshButton = () => {
  const dispatch = useDispatch();

  const refreshData = () => {
    dispatch(getExecutionsAction());
  };

  return (
    <BlueButton onClick={refreshData}>
      <FontAwesomeIcon icon='sync' />
    </BlueButton>
  );
};
