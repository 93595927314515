import { EOrderType, ETimeInForce } from '@deltix/cc-common';
import { merge, map, catchError, of } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import {
  switchMap,
  filter,
  distinctUntilChanged,
  mergeMap,
} from 'rxjs/operators';
import { ZERO, toDecimal, HUNDRED } from '@deltix/decimal-utils';
import {
  SOROrderSide,
  SOR_ELIGIBLE_DESTINATIONS_KEY,
  SOR_MARKUP_KEY,
} from '../../../constants';
import { AppEpic, IExecution, IExecutionRequest } from '../../../types';
import { getApi } from '../../api';
import { timeInForceConvert } from '../../utils/utils';
import {
  getCurrentFormSelector,
  getSideSelector,
} from '../selectors/app.selectors';
import { changeExecutionsAction } from '../slices/executions.slice';
import { getRequestUrlSelector } from '../selectors/appJson.selector';

const BPS = toDecimal('0.01');

export const requestExecutionsEpic: AppEpic = (action$, state$, dependencies) =>
  merge(
    state$.pipe(
      map(getCurrentFormSelector),
      filter((form) => !!form),
      distinctUntilChanged(),
    ),
    action$.pipe(
      filter((a) => a.type === '@GET_EXECUTIONS'),
      map((_) => getCurrentFormSelector(state$.value)),
    ),
  ).pipe(
    switchMap((form) => {
      if (!form) {
        return [
          changeExecutionsAction({
            executions: [],
            totalQty: void 0,
            totalCommission: void 0,
            avgPrice: void 0,
            timestamp: Date.now(),
            diagnostic: { ERROR: 'No form data' },
            total: void 0,
            sorCommission: void 0,
          }),
        ];
      } else if (!form.type) {
        return [
          changeExecutionsAction({
            executions: [],
            totalQty: void 0,
            totalCommission: void 0,
            avgPrice: void 0,
            timestamp: Date.now(),
            diagnostic: { ERROR: 'Unsupported order type' },
            total: void 0,
            sorCommission: void 0,
          }),
        ];
      }

      const side = getSideSelector(state$.value);

      const request: IExecutionRequest = {
        side: side,
        eligibleExchanges: form[SOR_ELIGIBLE_DESTINATIONS_KEY] as string,
        limitPrice:
          form.type !== EOrderType.limit
            ? void 0
            : form.limit_price
            ? toDecimal(form.limit_price).toFixed()
            : '',
        symbol: form.security_id,
        timeInForce: timeInForceConvert(form.time_in_force || ETimeInForce.ioc),
        quantity: form.quantity
          ? toDecimal(form.quantity).toFixed()
          : ZERO.toFixed(),
      };
      return state$.pipe(
        map(getRequestUrlSelector),
        filter((requestUrl) => !!requestUrl),
        distinctUntilChanged(),
        switchMap((requestUrl) => {
          return getApi()
            .getAccessToken()
            .pipe(
              mergeMap((token) => {
                return fromFetch(requestUrl, {
                  headers: {
                    authorization: token,
                    'Content-Type': 'application/json',
                  },
                  method: 'POST',
                  body: JSON.stringify(request),
                }).pipe(
                  switchMap((response) => {
                    if (response.ok) {
                      return response.json();
                    } else {
                      return of({
                        error: true,
                        message: `Error ${response.status}`,
                      });
                    }
                  }),
                  catchError((err) => {
                    console.error(err);
                    return of({ error: true, message: err.message });
                  }),
                );
              }),
              mergeMap((data) => {
                if (data.error) {
                  return [
                    changeExecutionsAction({
                      executions: [],
                      totalQty: void 0,
                      totalCommission: void 0,
                      avgPrice: void 0,
                      timestamp: Date.now(),
                      diagnostic: { ERROR: 'Server returned an error' },
                      total: void 0,
                      sorCommission: void 0,
                    }),
                  ];
                }
                const { diagnostic = {}, entries } = data;
                let totalQty = ZERO;
                let total = ZERO;
                let totalCommission = ZERO;
                let sorCommission = ZERO;
                let priceAdjustment = ZERO;

                return getApi()
                  .getSecurity(form.security_id)
                  .pipe(
                    mergeMap((security) => {
                      if (
                        security.custom_data2 &&
                        typeof security.custom_data2 === 'object'
                      ) {
                        const {
                          sorCommissionFlat,
                          sorCommissionProgressive,
                          sorPriceAdjustment,
                        } = security.custom_data2;
                        const flat =
                          sorCommissionFlat != null &&
                          toDecimal(sorCommissionFlat);
                        const progressive =
                          sorCommissionProgressive != null &&
                          toDecimal(sorCommissionProgressive);

                        if (flat) {
                          sorCommission = sorCommission.add(flat);
                        }
                        if (progressive) {
                          sorCommission = sorCommission.add(
                            totalQty.times(progressive),
                          );
                        }

                        priceAdjustment =
                          sorPriceAdjustment != null
                            ? toDecimal(sorPriceAdjustment)
                            : ZERO;
                      }

                      if (form[SOR_MARKUP_KEY]) {
                        priceAdjustment = toDecimal(
                          form[SOR_MARKUP_KEY] as string,
                        );
                      }

                      const executions = (entries || []).map(
                        (entry: IExecution) => {
                          const price = toDecimal(entry.price);
                          const quantity = toDecimal(entry.quantity);
                          const commission = toDecimal(entry.commission);

                          if (priceAdjustment) {
                            const correction = price.times(
                              priceAdjustment.times(BPS).div(HUNDRED),
                            );

                            sorCommission = sorCommission.plus(
                              correction.times(quantity),
                            );
                          }

                          if (quantity) {
                            totalQty = totalQty.add(quantity);
                            if (price) {
                              total = total.add(quantity.times(price));
                            }
                          }

                          if (commission) {
                            totalCommission = totalCommission.add(commission);
                          }

                          return {
                            ...entry,
                            price,
                            quantity,
                            commission,
                          };
                        },
                      );

                      const avgExchangeCommission = totalQty.eq(ZERO)
                        ? totalQty
                        : totalCommission.div(totalQty);
                      totalCommission = totalCommission.add(sorCommission);

                      const avgPrice = totalQty.eq(ZERO)
                        ? ZERO
                        : total.div(totalQty);
                      const avgCommission = totalQty.eq(ZERO)
                        ? totalQty
                        : totalCommission.div(totalQty);

                      return [
                        changeExecutionsAction({
                          executions,
                          totalQty,
                          totalCommission,
                          avgPrice:
                            side === SOROrderSide.BUY
                              ? avgPrice.add(avgCommission)
                              : avgPrice.minus(avgCommission),
                          timestamp: Date.now(),
                          diagnostic,
                          total:
                            side === SOROrderSide.BUY
                              ? total.add(totalCommission)
                              : total.minus(totalCommission),
                          sorCommission,
                          avgExchangePrice:
                            side === SOROrderSide.BUY
                              ? avgPrice.add(avgExchangeCommission)
                              : avgPrice.minus(avgExchangeCommission),
                          quantity_presicion: security.quantity_precision,
                        }),
                      ];
                    }),
                  );
              }),
            );
        }),
      );
    }),
  );
