import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IExecutionsState } from '../../../types';

const executionsState = {
  diagnostic: {},
  executions: [],
  totalQty: null,
  totalCommission: null,
  avgPrice: null,
  timestamp: null,
  total: null,
  sorCommission: null,
  avgExchangePrice: null,
} as unknown as IExecutionsState;

export const executionsSlice = createSlice({
  name: 'executions',
  initialState: executionsState,
  reducers: {
    changeExecutionsAction: (
      state,
      action: PayloadAction<IExecutionsState>,
    ) => {
      const {
        diagnostic,
        executions,
        totalQty,
        totalCommission,
        avgPrice,
        timestamp,
        total,
        sorCommission,
        avgExchangePrice,
        quantity_presicion,
      } = action.payload;

      state.diagnostic = diagnostic;
      state.executions = executions;
      state.totalQty = totalQty;
      state.totalCommission = totalCommission;
      state.avgPrice = avgPrice;
      state.timestamp = timestamp;
      state.total = total;
      state.sorCommission = sorCommission;
      state.avgExchangePrice = avgExchangePrice;
      state.quantity_presicion = quantity_presicion;
    },
  },
});

export const { changeExecutionsAction } = executionsSlice.actions;

export default executionsSlice.reducer;
