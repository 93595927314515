import styled from '@emotion/styled';
import {
  getTheme,
  minHeight,
  fontSize,
  fontFamily,
  Flex,
  padding,
} from '@deltix/cc-ui-kit';

export const DropdownListItem = styled(Flex)<{ active?: boolean }>(
  minHeight(8),
  fontSize(2),
  fontFamily('roboto'),
  padding(0, 2),
  getTheme(['dropdown', 'item', 'base']),
  {
    cursor: 'pointer',
    textOverflow: 'ellipsis',
    alignItems: 'center',
    '&:first-of-type': {
      borderTop: 0,
    },
  },
  ({ active, ...props }) => [
    {
      '&:hover': [getTheme(['dropdown', 'item', 'hover'])(props)],
    },
    active && getTheme(['dropdown', 'item', 'active'])(props),
  ],
);
