import { useSelector } from 'react-redux';
import { getVisibleDiagnosticSelector } from '../../store/selectors/app.selectors';
import { prettyPrint } from '../../utils/utils';
import { ExecutionsRow } from '../executions/ExecutionsRow';

interface IDiagnosticProp {
  diagnostic: { [exchange: string]: string };
}

export const Diagnostic = ({ diagnostic }: IDiagnosticProp) => {
  const isVisibleDiagnostic = useSelector(getVisibleDiagnosticSelector);

  return (
    <>
      {isVisibleDiagnostic &&
        Object.keys(diagnostic).map((key) => (
          <ExecutionsRow
            flex1Used
            key={key}
            textValue={key}
            numberValue={prettyPrint(diagnostic[key])}
          />
        ))}
    </>
  );
};
