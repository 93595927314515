import Big from 'big.js';
import styled from '@emotion/styled';
import {
  FlexRows,
  margin,
  fontFamily,
  fontSize,
  colors2,
  getTheme,
} from '@deltix/cc-ui-kit';
import { formatNumberByDelimiter, formatValue } from '../../utils/utils';
import { useSelector } from 'react-redux';
import { getLocaleSelector } from '../../store/selectors/app.selectors';
import { getNumberDecimalSeparator } from '../../utils/useNumberDecimalSeparator';

interface IExecutionsRow {
  textValue: string;
  numberValue: number | Big | string;
  flex1Used?: boolean;
  presicion?: number;
}

const StyledFlexRows = styled(FlexRows)(
  {
    justifyContent: 'space-between',
  },
  margin(1, 0),
);

const Text = styled('span')<{ flex1Used?: boolean }>(
  {
    textTransform: 'uppercase',
    color: colors2.colorc5c5c5,
  },
  ({ flex1Used }) => flex1Used && { flex: 1 },
  fontFamily('roboto'),
  fontSize(2),
);

const StyledText = styled(Text)<{ flex1Used?: boolean }>(
  ({ flex1Used }) => flex1Used && { maxWidth: '110px' },
);

const Number = styled(Text)<{ flex1Used?: boolean }>(
  {
    textAlign: 'right',
  },
  getTheme('input', 'base'),
  ({ flex1Used }) => ({
    textTransform: `${flex1Used ? 'none' : 'uppercase'}`,
  }),
);

export const ExecutionsRow = ({
  textValue,
  numberValue,
  flex1Used,
  presicion,
}: IExecutionsRow) => {
  const locale = useSelector(getLocaleSelector);
  const separator = getNumberDecimalSeparator(locale);

  return (
    <StyledFlexRows>
      <StyledText flex1Used={flex1Used}>{textValue}</StyledText>
      <Number flex1Used={flex1Used}>
        {typeof numberValue === 'number' || numberValue instanceof Big
          ? formatNumberByDelimiter(
              formatValue(numberValue, presicion),
              separator,
              '',
            )
          : numberValue}
      </Number>
    </StyledFlexRows>
  );
};
