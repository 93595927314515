import styled from '@emotion/styled';
import { Button } from '@deltix/cc-ui-kit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { getVisibleDiagnosticSelector } from '../../store/selectors/app.selectors';
import { changeVisibleDiagnosticAction } from '../../store/slices/app.slice';
import { toLocalStorage } from '../../utils/localStorage';
import { localStorageKey } from '../../../constants';

const TransparentButton = styled(Button)<{ isVisibleDiagnostic?: boolean }>(
  ({ isVisibleDiagnostic }) => ({
    backgroundColor: 'transparent',
    opacity: `${!isVisibleDiagnostic ? '0.5' : '1'}`,
    '&:hover': { opacity: 1 },
  }),
);

export const DiagnosticToggle = () => {
  const dispatch = useDispatch();

  const isVisibleDiagnostic = useSelector(getVisibleDiagnosticSelector);

  const changeVisible = () => {
    toLocalStorage(
      localStorageKey.SOR_VISIBLE_DIAGNOSTIC,
      !isVisibleDiagnostic,
    );
    dispatch(changeVisibleDiagnosticAction(!isVisibleDiagnostic));
  };
  return (
    <TransparentButton
      isVisibleDiagnostic={isVisibleDiagnostic}
      onClick={changeVisible}
    >
      <FontAwesomeIcon icon='info-circle' />
    </TransparentButton>
  );
};
