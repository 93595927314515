import { IWidgetApi, WidgetApi } from '@deltix/cc-widget-api';
import { createContext, FC, ReactNode, useContext } from 'react';

const context = createContext<IWidgetApi>(void 0 as any);
let globalApi: IWidgetApi;

export const ApiProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const api = getApi();
  return <context.Provider value={api}>{children}</context.Provider>;
};

export const useApi = () => {
  const api = useContext(context);

  return api;
};

export const getApi = () => {
  if (globalApi) {
    return globalApi;
  }
  const params = new URLSearchParams(globalThis.location.search.slice(1));
  return (globalApi = new WidgetApi(params.get('widgetId') as string));
};
