import { IExecution } from '../../../types';
import { ExecutionsLabel } from '../../../constants';
import { ExecutionsRow } from './ExecutionsRow';
import { convertExponentialToDecimal } from '../../utils/utils';

export const Execution = ({
  execution,
  quantityPresicion,
}: {
  execution: IExecution;
  quantityPresicion?: number;
}) => {
  const { quantity, price, commission, exchange } = execution;

  return (
    <>
      <ExecutionsRow
        textValue={ExecutionsLabel.QUANTITY}
        numberValue={convertExponentialToDecimal(quantity)}
        presicion={quantityPresicion}
      />
      <ExecutionsRow textValue={ExecutionsLabel.PRICE} numberValue={price} />
      <ExecutionsRow
        textValue={ExecutionsLabel.COMMISSION}
        numberValue={commission}
      />
      <ExecutionsRow
        textValue={ExecutionsLabel.EXCHANGE}
        numberValue={exchange}
      />
    </>
  );
};
