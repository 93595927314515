import styled from '@emotion/styled';
import Downshift from 'downshift';
import { DropdownList } from '../dropdown/DropdownList';
import { DropdownListItem } from '../dropdown/DropdownListItem';
import { useDispatch, useSelector } from 'react-redux';
import {
  getActiveFormSelector,
  getSecurityNamesSelector,
} from '../../store/selectors/app.selectors';
import { changeActiveFormAction } from '../../store/slices/app.slice';
import { INewOrder } from '@deltix/cc-common';
import { toLocalStorage } from '../../utils/localStorage';
import { localStorageKey } from '../../../constants';
import { useCallback, useEffect } from 'react';
import { DropdownInput } from '../Input/DropdownInput';

const SecuritiesWrapper = styled('div')({
  display: 'flex',
  width: '100px',
  position: 'relative',
});

const DropdownSecuritiesWrapper = styled('div')({
  maxHeight: '200px',
  overflowX: 'hidden',
});

export const DropdownSecurities = ({
  listItems,
}: {
  listItems: INewOrder[];
}) => {
  const dispatch = useDispatch();
  const activeForm: number = useSelector(getActiveFormSelector);
  const securityNames = useSelector(getSecurityNamesSelector);

  const changeSelectedItem = useCallback(
    (securityId: string) => {
      toLocalStorage(localStorageKey.SOR_ACTIVE_FORM, securityId);
      dispatch(changeActiveFormAction(securityId));
    },
    [dispatch],
  );

  useEffect(() => {
    if (!!listItems.length && activeForm === 0) {
      changeSelectedItem(listItems[0].security_id);
    }
  }, [listItems, activeForm, changeSelectedItem]);

  const activeItem = listItems?.[activeForm];
  const value =
    securityNames[activeItem?.security_id] || activeItem.security_id;

  return !!listItems.length && activeItem ? (
    <Downshift>
      {({ isOpen, openMenu, closeMenu, getRootProps }) => {
        return (
          <SecuritiesWrapper {...getRootProps()}>
            <DropdownInput
              value={value}
              onClick={isOpen ? closeMenu : openMenu}
            />
            {isOpen && (
              <DropdownList>
                <DropdownSecuritiesWrapper>
                  {!!listItems.length &&
                    listItems.map((item, index: number) => (
                      <DropdownListItem
                        key={item.security_id + index}
                        data-id={index}
                        active={index === activeForm}
                        onClick={() => {
                          changeSelectedItem(item.security_id);
                          isOpen ? closeMenu() : openMenu();
                        }}
                      >
                        {securityNames[item.security_id] || item.security_id}
                      </DropdownListItem>
                    ))}
                </DropdownSecuritiesWrapper>
              </DropdownList>
            )}
          </SecuritiesWrapper>
        );
      }}
    </Downshift>
  ) : null;
};
