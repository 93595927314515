import { OrderSideType } from './types';

export const SOR_MARKUP_KEY = '7242';

export const SOR_ELIGIBLE_DESTINATIONS_KEY = '7002';

export const DEFAULT_DATETIME_FORMAT = 'yyyy-MM-dd HH:mm:ss';

export const LIST_SIDE: OrderSideType[] = ['BUY', 'SELL'];

export enum ExecutionsLabel {
  TOTAL_QUANTITY = 'TOTAL QUANTITY',
  COVER_PRICE = 'COVER PRICE',
  TOTAL_COMMISSION = 'TOTAL COMMISSION',
  SOR_COMMISSION = 'SOR COMMISSION',
  AVG_PRICE = 'AVG PRICE',
  TOTAL = 'TOTAL',
  TIMESTAMP = 'TIMESTAMP',
  QUANTITY = 'QUANTITY',
  PRICE = 'PRICE',
  COMMISSION = 'COMMISSION',
  EXCHANGE = 'EXCHANGE',
}

export enum SORTimeInForce {
  DAY = 'DAY',
  GOOD_TILL_CANCEL = 'GOOD_TILL_CANCEL',
  AT_THE_OPENING = 'AT_THE_OPENING',
  IMMEDIATE_OR_CANCEL = 'IMMEDIATE_OR_CANCEL',
  FILL_OR_KILL = 'FILL_OR_KILL',
  GOOD_TILL_CROSSING = 'GOOD_TILL_CROSSING',
  GOOD_TILL_DATE = 'GOOD_TILL_DATE',
  AT_THE_CLOSE = 'AT_THE_CLOSE',
}

export enum SOROrderSide {
  BUY = 'BUY',
  SELL = 'SELL',
}

export enum localStorageKey {
  SOR_SIDE = 'sor_side',
  SOR_ACTIVE_FORM = 'sor_active_form',
  SOR_VISIBLE_DIAGNOSTIC = 'sor_visible_diagnostic',
}
